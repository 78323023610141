<template>
  <v-dialog
    v-model="bDdialog"
    scrollable
    transition="dialog-top-transition"
    open-delay="300"
    :max-width="sMaxWidth"
    v-bind="$attrs"
    @input="onInput"
  >
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <v-card flat tile :elevation="0" :loading="loading">
      <slot name="title" />

      <v-card-text>
        <slot />
      </v-card-text>

      <v-card-actions v-if="!hideActions">
        <slot name="actions" />

        <template v-if="!hasActionsSlot">
          <slot name="append-actions" />
          <v-spacer />
          <v-btn text color="primary" @click="close">
            {{ $t("close") }}
          </v-btn>
          <slot name="prepend-actions" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class GwDialog extends Vue {
  @Prop(Boolean) readonly value!: boolean;
  @Prop(Boolean) readonly hideActions!: boolean;
  @Prop(Boolean) readonly loading!: boolean;

  bDdialog = false;

  @Watch("bDdialog")
  onOpenCloseDialog(bVal: boolean) {
    this.$emit(`${bVal ? "open" : "close"}:dialog`);
  }

  @Watch("value")
  onChangeValue(bVal: boolean) {
    this.bDdialog = bVal;
  }

  onInput(bVal: boolean) {
    this.dialog = bVal;
    // this.$emit("input", bVal);
  }

  mounted() {
    this.close();
  }

  close() {
    this.dialog = false;
    // this.$emit("input", false);
  }

  get dialog() {
    return this.value;
  }

  set dialog(bVal: boolean) {
    this.bDdialog = bVal;
    this.$emit("input", bVal);
  }

  get sMaxWidth() {
    let fWidth = "100%";
    const obBreakPoint = this.$vuetify.breakpoint;

    if (obBreakPoint.lg) {
      fWidth = "800px";
    } else if (obBreakPoint.xl) {
      fWidth = "1200px";
    }

    return fWidth;
  }

  get hasActionsSlot() {
    return !!this.$_.get(this.$slots, "actions");
  }
}
</script>
