import CacheMixin from "@/mixins/CacheMixin";
import type { ProductGwData, TaxTypeData } from "@planetadeleste/vue-mc-gw";
import { TaxTypeCollection } from "@planetadeleste/vue-mc-gw";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins, Prop } from "vue-property-decorator";
import type { MaybeOfferData } from "@/types/utils";
import { number } from "mathjs";

@Component
export default class TaxTypeCacheMixin extends Mixins(CacheMixin) {
  @Prop(Object) readonly offer!: MaybeOfferData;
  @Prop(Number) readonly taxTypeId!: number;
  @Prop(Object) readonly taxType!: TaxTypeData;

  obTaxTypeCollection: TaxTypeCollection = new TaxTypeCollection();
  obProduct: Product = new Product();

  get obTaxType() {
    if (!this.taxTypeId && !this.taxType && !this.offer) {
      return undefined;
    }

    if (this.taxType) {
      return this.taxType;
    }

    let iTaxTypeId = this.taxTypeId;

    if (!iTaxTypeId && this.obProductGw && this.obProductGw.taxtype_id) {
      iTaxTypeId = this.obProductGw.taxtype_id;
    }

    if (iTaxTypeId) {
      return this.obTaxTypeCollection.find({ id: iTaxTypeId });
    }

    return undefined;
  }

  get taxPercent() {
    return this.obTaxType ? number(this.obTaxType.percent) : 0;
  }

  get obProductGw(): ProductGwData {
    return this.obProduct ? this.obProduct.get("product_gw") : null;
  }

  get cacheKey() {
    return "TAXTYPE";
  }

  get cacheIdentifier() {
    return "items";
  }

  mounted() {
    this.$nextTick(this.loadTaxTypes);
  }

  async loadTaxTypes() {
    await this.loadProduct();
    const arItems = this.cache<TaxTypeData[]>();
    this.obTaxTypeCollection.clear();

    if (arItems && arItems.length) {
      this.$_.forEach(arItems, (obItem) => {
        this.obTaxTypeCollection.add(obItem);
      });

      return;
    }

    await this.obTaxTypeCollection.list();
    const arCacheItems: Partial<TaxTypeData>[] = [];
    this.obTaxTypeCollection.each((obModel) => {
      arCacheItems.push(obModel.attributes);
    });

    this.cache(arCacheItems);
  }

  async loadProduct() {
    if (this.taxTypeId || this.taxType || !this.offer) {
      return;
    }

    if (this.offer && this.offer.product_id) {
      this.obProduct = new Product({ id: this.offer.product_id });
      await this.obProduct.fetch();
    }
  }
}
