<template>
  <form-field-text
    v-model="sCode"
    :disabled="loading"
    :label="sLabel"
    :loading="loading"
    :required="required"
  >
    <template v-if="withBtn && itemKey" #append>
      <v-btn
        color="primary"
        depressed
        small
        @click="getCode"
        v-text="$t('get.code')"
      />
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Utils from "@/services/Utils";

@Component
export default class CodeFieldText extends Vue {
  @VModel({ type: [String, Number] }) sCode!: string;
  @Prop(String) readonly itemKey!: string;
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly showCodeButton!: boolean;
  @Prop(Boolean) readonly autoAssign!: boolean;

  loading = false;

  get sLabel() {
    return this.label || "code";
  }

  get withBtn() {
    return this.showCodeButton && !!this.itemKey;
  }

  async getCode() {
    if (!!this.sCode || (!this.withBtn && !this.autoAssign)) {
      return;
    }

    this.loading = true;
    const obResponse = await Utils.nextCode(this.itemKey);
    this.$emit("input", obResponse.data);
    this.loading = false;
  }

  async mounted() {
    if (this.autoAssign) {
      await this.getCode();
    }
  }
}
</script>
