import { Component, Vue } from "vue-property-decorator";
import { GwCacheModule } from "@/store/cache";
import { get, isNumber, isObjectLike, isString } from "lodash";

@Component
export default class CacheMixin extends Vue {
  get cacheKey() {
    return "GW";
  }

  get cacheIdentifier() {
    return "id";
  }

  get cacheData() {
    return GwCacheModule.cache;
  }

  cache<T extends Record<string, any>>(
    obData?: T | number | string,
    sCacheKey?: string
  ): T | undefined {
    if (!sCacheKey) {
      sCacheKey = this.cacheKey;
    }

    const iDataId = !obData
      ? this.cacheIdentifier
      : isNumber(obData) || isString(obData)
      ? obData
      : get(obData, this.cacheIdentifier, this.cacheIdentifier);

    const sKey = `${sCacheKey}.${iDataId}`;

    if (obData && isObjectLike(obData)) {
      GwCacheModule.set({ [sKey]: obData });
    }

    return get(this.cacheData, sKey);
  }

  clearCache(sCacheKey: string) {
    GwCacheModule.forget(sCacheKey);
  }
}
