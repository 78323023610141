<template>
  <td :class="css">
    <template v-if="isMobile">
      <div class="v-data-table__mobile-row__header" v-text="label" />
      <div class="v-data-table__mobile-row__cell">
        <slot />
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </td>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TableCell extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly loading!: boolean;

  get css() {
    return {
      "v-data-table__mobile-row": this.isMobile,
      loading: this.loading,
    };
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }
}
</script>

<style lang="scss">
.v-data-table__mobile-row__header {
  min-width: 40%;
}
</style>
