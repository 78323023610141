<template>
  <ValidationProvider
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-combobox
        v-model="sCode"
        :error-messages="errors"
        :filter="onFilter"
        :hide-details="showHint ? false : 'auto'"
        :hint="sHint"
        :items="items"
        :label="hideLabel ? undefined : $t(label)"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :persistent-hint="showHint"
        :return-object="false"
        :success="required ? valid : undefined"
        clearable
        dense
        item-text="code"
        item-value="code"
        outlined
        v-bind="$attrs"
        @change="onChange"
      >
        <template #selection="{ item }">
          <account-code-preview
            v-if="itemSelected"
            :item="itemSelected"
            inline
          />
          <span v-else v-text="item" />
        </template>

        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
            <v-list-item-subtitle v-text="item.code" />
          </v-list-item-content>
        </template>

        <!-- Pass on all named slots -->
        <template v-for="(_, name) in $slots" v-slot:[name]>
          <slot :name="name" />
        </template>
      </v-combobox>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import type { AccountData } from "@planetadeleste/vue-mc-gw";
import { Account, AccountCollection } from "@planetadeleste/vue-mc-gw";
import { filter, find, first, get, isEmpty, map } from "lodash";
import { AppModule } from "@/store/app";
import { AccountModule } from "@/store/account";
import AccountCodePreview from "@/modules/accounts/components/AccountCodePreview.vue";

@Component({
  components: { AccountCodePreview },
})
export default class AccountCodeSelect extends Vue {
  @VModel({ type: [String, Number] }) sCode!: number | string;
  @Prop({ type: String, default: "account.code" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(String) readonly paymentMethodCode!: string;
  @Prop([String, Number]) currencyId!: number;

  obCollection: AccountCollection = new AccountCollection();
  loading = false;

  get company() {
    return AppModule.company;
  }

  get accItems() {
    return AccountModule.items;
  }

  get items() {
    return filter(this.accItems, (obItem) => {
      if (!this.paymentMethodCode) {
        return true;
      }

      const arCurrencies: number[] = get(obItem, "currencies", []);
      let bReturn: boolean = this.paymentMethodCode
        ? get(obItem, "paymentmethod.code", "") === this.paymentMethodCode
        : true;

      if (
        this.currencyId &&
        !!obItem.currency_id &&
        !isEmpty(arCurrencies) &&
        bReturn
      ) {
        bReturn =
          this.currencyId == obItem.currency_id ||
          arCurrencies.includes(this.currencyId);
      }

      return bReturn;
    });
  }

  get arCodeList(): (string | number)[] {
    return map(this.items, "code") as (string | number)[];
  }

  get itemSelected(): Partial<AccountData> | undefined {
    return this.sCode
      ? (find(this.items, { code: this.sCode }) as
          | Partial<AccountData>
          | undefined)
      : undefined;
  }

  get showHint(): boolean {
    return !!this.sCode && !this.arCodeList.includes(this.sCode);
  }

  get sHint() {
    return !this.showHint ? undefined : this.$t("account.code.not.found");
  }

  @Watch("paymentMethodCode")
  async onChangePaymentMethod() {
    await this.load();
  }

  async mounted() {
    await this.load();
    this.autoAssign();
  }

  autoAssign() {
    if (this.items.length === 1) {
      const sCode = get(first(this.items), "code") as number | string;
      this.onChange(sCode);
    }
  }

  onChange(sValue: number | string) {
    const obData = find(this.items, { code: sValue }) as
      | Partial<AccountData>
      | undefined;
    this.$emit("change", obData ? new Account(obData) : null);
    this.$emit("input", sValue);
  }

  onFilter(item: AccountData, queryText: string, itemText: string): boolean {
    const sName = item.name.toLowerCase();
    const sCode = itemText.toLowerCase();
    const sQuery = queryText.toLowerCase();

    const inName = sName.indexOf(sQuery);
    const inCode = sCode.indexOf(sQuery);

    return inName > -1 || inCode > -1;
  }

  async load() {
    if (this.items.length) {
      return;
    }

    const obFilters: Record<string, any> = { company: this.company.id };

    this.loading = true;
    await AccountModule.load(obFilters);
    this.loading = false;
  }
}
</script>
